import IconCloseX from "assets/images/IconCloseX";
import IconFile from "assets/images/IconFile";
import React from "react";
import { calcFileSize } from "utils";

const FILE_PSD_TYPE = "image/vnd.adobe.photoshop";

const CardFileSelect = ({ file, handleClearFile, isLoading, isSuccess }) => {
  return (
    <div
      className={`border border-slate-300 rounded-lg pl-2 py-1 pr-0 max-w-[300px] text-xs ${
        file?.type !== FILE_PSD_TYPE &&
        "border-dashed !border-red-400 bg-red-100"
      }`}
    >
      <div className="flex-1 flex flex-wrap items-center gap-x-2">
        <div className="flex items-center">
          <IconFile
            width={20}
            height={20}
            fill={`${file?.type !== FILE_PSD_TYPE ? "#000" : "#d4d4d8"}`}
          />
          <span className="inline-block max-w-[100px] truncate">
            {file?.name}
          </span>
        </div>
        <span>{calcFileSize(file?.size) + "KB"}</span>

        <button
          onClick={handleClearFile}
          disabled={isLoading}
          className={`${(isLoading || isSuccess) && "invisible"}`}
        >
          <IconCloseX
            width={20}
            height={20}
            fill={`${file?.type !== FILE_PSD_TYPE ? "#475569" : "#d4d4d8"}`}
          />
        </button>
      </div>
    </div>
  );
};

export default CardFileSelect;
